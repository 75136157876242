import { DISABLE_STICKY_PROMPT_CARD, ENABLE_STICKY_PROMPT_CARD } from './actionConstants'
import { AppAction } from './ActionTypes'

export function enableStickyPromptCard(): AppAction {
  return { type: ENABLE_STICKY_PROMPT_CARD }
}

export function disableStickyPromptCard(): AppAction {
  return { type: DISABLE_STICKY_PROMPT_CARD }
}

export function disableStickyPromptCardWithCooldown(cooldown: number): AppAction {
  return (dispatch) => {
    if (cooldown > 0) {
      // disable all card globally
      dispatch(disableStickyPromptCard())

      // re-enable it after cooldown time
      setTimeout(() => {
        dispatch(enableStickyPromptCard())
      }, cooldown)
    }
  }
}
