import { useRecentTrips } from 'tripPlanner/hooks/api/trip'
import { BasicTrip } from 'tripPlanner/types/common'
import { EmptyArray } from 'lib/array/arrayUtils'
import moment from 'moment'
import { getDismissalState } from 'tripPlanner/utils/tripReengagement'
import config from 'constants/config'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { isMobileAppSel } from 'selectors/configSelectors'
import { useAppSelector, shallowEqual } from 'hooks/reduxHooks'
import { useEffect, useState } from 'react'
import { useUserSettings } from 'tripPlanner/hooks/api/userSettings'

function getMinTimeThreshold() {
  let thresholdHours = parseFloat(config.TRIP_REENGAGEMENT_CARD_MIN_TIME_HOURS)
  if (isNaN(thresholdHours)) { thresholdHours = 36 }

  return moment().subtract(thresholdHours, 'hours')
}

function meetsReengagementCriteria(trip: BasicTrip) {
  if (trip.role !== 'OWNER') {
    return false
  }

  const interactionTime = trip.interactedAt
  const tripEndDate = trip.endDate

  // Don't show if not touched in 3 months
  const maxTimeThreshold = moment().subtract(3, 'months')
  if (interactionTime.isBefore(maxTimeThreshold)) {
    return false
  }
  // Don't show if touched in last 36 hours (or overridden threshold)
  if (interactionTime.isAfter(getMinTimeThreshold())) {
    return false
  }
  // Don't show if trip is in the past
  if (tripEndDate?.isBefore(moment())) {
    return false
  }

  return true
}

export default function useTripReengagementSelectionLogic({
  tripReengagementCardEnabled,
}: {
  tripReengagementCardEnabled: boolean,
}): {
  isTripDataReady: boolean,
  canShowTripCard: boolean,
  setTripCardDismissed: () => void,
  trip: BasicTrip | undefined,
} {
  // Tracks whether the trip re-engagement card has been dismissed
  const [tripCardIsDismissed, setTripCardDismissedState] = useState(true)
  const setTripCardDismissed = () => setTripCardDismissedState(true)

  const { data: userSettings, isLoading: isLoadingUserSettings } = useUserSettings()

  useEffect(() => {
    if (userSettings) {
      setTripCardDismissedState(getDismissalState(userSettings).isDismissed)
    }
  }, [userSettings, isLoadingUserSettings])

  const {
    isUserLoggedIn,
    isInMobileWebView,
  } = useAppSelector((state) => ({
    isUserLoggedIn: selectLoggedIn(state),
    isInMobileWebView: isMobileAppSel(state),
  }), shallowEqual)

  const canUseRecentTrips = (
    !isInMobileWebView &&
    tripReengagementCardEnabled &&
    !!config.ENABLE_TRIP_PLANNER &&
    !!config.ENABLE_TRIP_REENGAGEMENT_CARD &&
    isUserLoggedIn
  )

  const { data: trips = EmptyArray as Array<BasicTrip>, isFetched: isRecentTripsFetched } = useRecentTrips({ enabled: canUseRecentTrips })
  const trip = trips[0] // Sorted by interaction time, so first is most recent

  const canShowTripCard = (
    canUseRecentTrips &&
    !isLoadingUserSettings &&
    !tripCardIsDismissed &&
    !!trip &&
    meetsReengagementCriteria(trip)
  )

  return {
    isTripDataReady: !canUseRecentTrips || isRecentTripsFetched,
    canShowTripCard,
    setTripCardDismissed,
    trip,
  }
}
