import { fetchOffersById } from 'actions/OfferActions'
import { fetchOrders } from 'actions/OrderActions'
import { getLatestCompletedReservation, haveOrdersBeenFetched, sortOrdersByDeparture } from 'lib/order/orderUtils'
import { useEffect, useMemo } from 'react'
import { getUpcomingOrders } from 'selectors/orderSelectors'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { isBookingWithinDateRange } from 'lib/datetime/dateUtils'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { EmptyArray } from 'lib/array/arrayUtils'
import { usePlaceByCoords } from 'hooks/usePlace'

export interface HotelOrderData {
  place?: App.Place,
  coords: App.GeoCoords,
  dates: { checkIn: string, checkOut: string },
  propertyName: string;
  hasFlight: boolean;
  flightOrderId?: string
  flightItemId?: string
}

function hasCarBookingWithinSamePeriod(checkIn: string, checkOut: string, carHireOrders: Array<App.Order>) {
  return carHireOrders.some((carHireOrder) => {
    const carPickUpDate = carHireOrder.carHireItems[0].reservation?.pickUp.date.split('T') || ['']
    const carDropOffDate = carHireOrder.carHireItems[0].reservation?.dropOff.date.split('T') || ['']
    return isBookingWithinDateRange(carPickUpDate[0], carDropOffDate[0], checkIn, checkOut)
  })
}

function useOrderCarHire() {
  const dispatch = useAppDispatch()

  const ordersFetched = useAppSelector((state: App.State) => haveOrdersBeenFetched(state, 'upcoming'))

  // only start evaluating the orders when they are all fetched
  const upcomingOrders = useAppSelector<Array<App.Order>>((state: App.State) => ordersFetched ? getUpcomingOrders(state) : EmptyArray)

  const offers = useAppSelector((state: App.State) => state.offer.offers)

  useEffect(() => {
    if (!ordersFetched) {
      dispatch(fetchOrders({ status: 'upcoming' }))
    }
  }, [dispatch, ordersFetched])

  const hotelOrders = useMemo(() => {
    const orders = Object.values(upcomingOrders).filter(order => order.items.length > 0 || order.bedbankItems.length > 0)
    return sortOrdersByDeparture(orders)
  }, [upcomingOrders])

  const carHireOrders = useMemo(() => {
    const orders = Object.values(upcomingOrders).filter(order => order.carHireItems.length > 0)
    return sortOrdersByDeparture(orders)
  }, [upcomingOrders])

  useEffect(() => {
    const offerIds = hotelOrders.filter(order => order.items.length > 0).map(order => order.items[0].offerId)
    dispatch(fetchOffersById(offerIds))
    // eslint-disable-next-line
  }, [hotelOrders])

  // now find the property coordinates so we can find the matching place ID
  const orderData = useMemo(() => {
    const order = hotelOrders.find(order => {
      if (order.bedbankItems[0]) {
        return !hasCarBookingWithinSamePeriod(
          order.bedbankItems[0].checkIn.format(ISO_DATE_FORMAT),
          order.bedbankItems[0].checkOut.format(ISO_DATE_FORMAT),
          carHireOrders,
        )
      }

      const item = getLatestCompletedReservation(order)
      if (item) {
        return !hasCarBookingWithinSamePeriod(item?.reservation?.startDate || '', item?.reservation?.endDate || '', carHireOrders)
      }

      return false
    })

    if (order) {
      if (order.bedbankItems[0]) {
        const property = order.bedbankItems[0].offer.property
        const hasFlight = order.hasFlight
        const flightIds = {
          orderId: hasFlight ? order.flightItems[0].orderId : null,
          itemId: hasFlight ? order.flightItems[0].itemId : null,
        }
        return {
          latitude: property.latitude,
          longitude: property.longitude,
          propertyName: property.name,
          checkIn: order.bedbankItems[0].checkIn.format(ISO_DATE_FORMAT),
          checkOut: order.bedbankItems[0].checkOut.format(ISO_DATE_FORMAT),
          hasFlight,
          flightOrderId: flightIds.orderId,
          flightItemId: flightIds.itemId,
        }
      } else {
        // we know it must have a hotel item as we already pre filtered
        const property = offers[order.items[0].offerId]?.property

        const item = getLatestCompletedReservation(order)
        const hasFlight = order.hasFlight
        const flightIds = {
          orderId: hasFlight ? order.flightItems[0].orderId : null,
          itemId: hasFlight ? order.flightItems[0].itemId : null,
        }
        if (property && item) {
          return {
            latitude: property.latitude,
            longitude: property.longitude,
            propertyName: property.name,
            checkIn: item.reservation.startDate,
            checkOut: item.reservation.endDate,
            hasFlight,
            flightOrderId: flightIds.orderId,
            flightItemId: flightIds.itemId,
            locationHeading: offers[order.items[0].offerId].locationHeading,
          }
        }
      }
    }
  }, [carHireOrders, hotelOrders, offers])

  const [place] = usePlaceByCoords(orderData?.latitude, orderData?.longitude)

  const uniqueHotelOrder = useMemo((): HotelOrderData | undefined => {
    if (orderData) {
      const value = {
        coords: { latitude: orderData.latitude, longitude: orderData.longitude },
        dates: { checkIn: orderData.checkIn, checkOut: orderData.checkOut },
        propertyName: orderData.propertyName,
        place,
        hasFlight: orderData?.hasFlight,
        ...(orderData?.hasFlight && {
          flightOrderId: orderData.flightOrderId || '',
          flightItemId: orderData.flightItemId || '',
        }),
      }

      return value
    }
  }, [orderData, place])

  return {
    isHotelOrderFetched: ordersFetched,
    uniqueHotelOrder,
    hotelOrders,
  }
}

export default useOrderCarHire
