import cn from 'clsx'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

import {
  SVG_ICON_SIZE_CSS_VAR,
  SvgIconComponent,
  SvgIconProps,
} from 'components/Luxkit/SvgIcon'

// Disable eslint so prettier doesn't mess everything up
/* eslint-disable */
const Circle = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
  height: var(${SVG_ICON_SIZE_CSS_VAR});
  width: var(${SVG_ICON_SIZE_CSS_VAR});

  &.colour-neutral-one { background-color: ${props => props.theme.palette.neutral.default.one}; }
  &.colour-neutral-two { background-color: ${props => props.theme.palette.neutral.default.two}; }
  &.colour-neutral-four { background-color: ${props => props.theme.palette.neutral.default.four}; }
  &.colour-neutral-eight { background-color: ${props => props.theme.palette.neutral.default.eight}; }
  &.colour-primary { background-color: ${props => props.theme.palette.brand.primary.normal}; }
  &.colour-primary-dark { background-color: ${props => props.theme.palette.brand.primary.dark}; }
  &.colour-secondary-normal { background-color: ${props => props.theme.palette.highlight.secondary.normalBackground}; }
  &.colour-success { background-color: ${props => props.theme.palette.messaging.success.normalBackground}; }
  &.colour-critical { background-color: ${props => props.theme.palette.messaging.critical.normalBackground}; }
  &.colour-critical-light { background-color: ${props => props.theme.palette.messaging.critical.lightBackground}; }
  &.colour-fast { background-color: ${props => props.theme.palette.highlight.tertiary.normalBackground}; }
  &.colour-warning { background-color: ${props => props.theme.palette.messaging.warning.normalBackground}; }
  &.colour-favourite { background-color: ${props => props.theme.palette.messaging.favourite.normalBackground}; }
  &.colour-highlight-primary-normal { background-color: ${props => props.theme.palette.highlight.primary.normalBackground}; }
`
/* eslint-enable */

interface Props {
  icon: SvgIconComponent
  circleColour?: SvgIconProps['colour']
  iconColour?: SvgIconProps['colour']
  size?: number
  iconSize?: SvgIconProps['size']
  className?: string
}

function IconInCircle({
  icon: Icon,
  circleColour,
  iconColour = 'neutral-eight',
  size = 16,
  iconSize = size * 0.6,
  className,
}: Props) {
  return (
    <Circle
      className={cn(
        className,
        circleColour !== undefined ? `colour-${circleColour}` : undefined,
      )}
      style={{ [SVG_ICON_SIZE_CSS_VAR]: rem(size) }}
    >
      <Icon colour={iconColour} size={iconSize} />
    </Circle>
  )
}

export default IconInCircle
