import { DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import { shallowEqual, useAppSelector } from 'hooks/reduxHooks'
import useToggle from 'hooks/useToggle'
import moment from 'moment'
import { useMemo } from 'react'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { getDefaultUserDriverCategory } from 'selectors/carHireSelectors'
import { getDismissalStateAndResetIfExpired } from 'storage/carHireReengagement'
import useOrderCarHire, { HotelOrderData } from '../../../hooks/CarHire/useOrderCarHire'
import useCarHireCheapestOffer from '../../../hooks/CarHire/useCarHireCheapestOffer'
import config from 'constants/config'

export interface CarHireReengagementView {
  offerSummary: App.CarHireOfferSummary | null
  offerCount: number
  pickUpLocationName: string
  dropOffLocationName: string
  placeName: string
  filters: App.CarHireOfferListFilters
}

interface Props {
  carHireReengagementCardEnabled: boolean;
}

interface isCarHireDataFetchedProps {
  isHotelOrderFetched: boolean
  uniqueHotelOrder: HotelOrderData | undefined
  carHireCheapestOfferList: App.CarHireCheapestOfferList
  hotelOrders: Array<App.Order>
}

function isCarHireDataFetched({
  uniqueHotelOrder,
  carHireCheapestOfferList,
  isHotelOrderFetched,
  hotelOrders,
}: isCarHireDataFetchedProps): boolean {
  if (isHotelOrderFetched) {
    if (hotelOrders.length === 0 || !uniqueHotelOrder) {
      return true
    }

    return Boolean(!carHireCheapestOfferList.fetching)
  }
  return false
}

export default function useCarHireReengagementSelectionLogic({ carHireReengagementCardEnabled }: Props) {
  const { isHotelOrderFetched, uniqueHotelOrder, hotelOrders } = useOrderCarHire()

  const checkIn = uniqueHotelOrder && moment(uniqueHotelOrder.dates.checkIn).format(DMY_CASUAL_SHORT_FORMAT)
  const checkOut = uniqueHotelOrder && moment(uniqueHotelOrder.dates.checkOut).format(DMY_CASUAL_SHORT_FORMAT)
  const [defaultUserAgeCategory, defaultUserAge] = useAppSelector(getDefaultUserDriverCategory)

  const filters: App.CarHireOfferListFilters = useMemo(() => {
    return {
      pickUpDate: uniqueHotelOrder?.dates.checkIn,
      dropOffDate: uniqueHotelOrder?.dates.checkOut,
      pickUpTime: '10:00',
      dropOffTime: '15:00',
      driversAgeCategory: defaultUserAgeCategory,
      driversAge: defaultUserAge,
      pickUpSearchType: 'geo',
      dropOffSearchType: 'geo',
      pickUpLocationId: uniqueHotelOrder?.place?.id,
      dropOffLocationId: uniqueHotelOrder?.place?.id,
    }
  }, [defaultUserAge, defaultUserAgeCategory, uniqueHotelOrder])

  const { isUserLoggedIn } = useAppSelector((state) => ({ isUserLoggedIn: selectLoggedIn(state) }), shallowEqual)

  const canUseOrderCarHire = (
    carHireReengagementCardEnabled &&
    isUserLoggedIn
  )

  const carHireCheapestOfferList = useCarHireCheapestOffer(filters, canUseOrderCarHire)

  const carHireOfferSummary = carHireCheapestOfferList.offerSummary
  const dismissalState = getDismissalStateAndResetIfExpired()
  const { value: carHireCardIsDismissed, on: setCarHireCardDismissed } = useToggle(dismissalState.isDismissed)

  const canShowCarHireCard = (
    config.CARHIRE_ENABLED &&
    config.CARHIRE_REENGAGEMENT_CARD_ENABLED &&
    !carHireCardIsDismissed &&
    carHireReengagementCardEnabled &&
    Boolean(carHireOfferSummary)
  )

  const carHireReengagementView: CarHireReengagementView = {
    offerSummary: carHireOfferSummary,
    offerCount: carHireOfferSummary?.offerCount || 0,
    pickUpLocationName: checkIn || '',
    dropOffLocationName: checkOut || '',
    placeName: uniqueHotelOrder?.place?.name || '',
    filters,
  }

  return {
    isCarHireDataReady:
      !canUseOrderCarHire ||
      isCarHireDataFetched({ carHireCheapestOfferList, isHotelOrderFetched, uniqueHotelOrder, hotelOrders }),
    carHireReengagementView,
    setCarHireCardDismissed,
    canShowCarHireCard,
  }
}
